<template>
  <section>
    <div class="content-header">
      <h2>What is career resilience?</h2>
    </div>
    <div class="content-wrapper">
      <p>Students and graduates are faced with challenging job searches, uncertainty, industry changes, job changes and on-the-job struggles more than ever before. Career paths are no longer linear. In an everchanging employment landscape, students need to navigate the terrain. A career could involve several job changes across many different sectors. </p>
      <p>Once they enter into a career, their work life is often unpredictable and not quite what they thought they were getting into.</p>
      <p>Career resilience refers to an individual process of recovery, adaptation and transformation in the face of challenging career situations, such as job search disappointments, job loss, career uncertainty and challenging workplaces in the context of a turbulent labour market and the changing nature of work. In order to develop career resilience, we need to use and build up our internal resources, such as optimism, flexibility, self-awareness and purpose, as well as have available and use external resources, such as supportive relationships, career services and community programs.</p>
      <p>We can help students and graduates navigate the world of work by creating environments that foster career resilience. However, we can’t lose sight of the fact that the development of career resilience is greatly affected by broader factors such as social bias, racism and ableism among others. Therefore, promoting its growth depends on a commitment to addressing and dismantling the systemic barriers that limit career outcomes. And as with any support educators provide, we need to:</p>
      <ul>
        <li>Ensure no harm</li>
        <li>Use resources efficiently and effectively</li>
        <li>Put students first</li>
      </ul>
      <p>This section of the toolkit provides effective and evidence-based practices for developing career resilience.</p>
      <p class="lm">Learn more</p>
      <p>There is no consensus about how to precisely define <em>career resilience</em>c, and many have defined it differently than we have here. For an in-depth analysis of the different interpretations of career resilience, see <a href="https://journals.sagepub.com/doi/full/10.1177/1534484317719622" target="_blank">Career Resilience: An Integrated Review of the Empirical Literature.</a></p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '01',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
